import Vue from 'vue';
import Router from 'vue-router';

import Landing from '@/app/pages/Landing';

Vue.use( Router );

let appRouter = new Router({
    mode: 'history',
    routes: [
        // generated-routes
        {
            path: '/',
            name: 'Landing',
            component: Landing
        },

        {
            path: '/kies',
            name: 'Kies',
            component: () =>
            {
                return import( /* webpackChunkName: "kies" */ '@/app/pages/Kies' );
            }
        },

        {
            path: '/jumping-jacks',
            // name: 'Move',
            component: () =>
            {
                return import( /* webpackChunkName: "move" */ '@/app/pages/Moves' );
            },
            meta: {
                type: 'jumping-jacks'
            }
        },

        {
            path: '/squats',
            // name: 'Move',
            component: () =>
            {
                return import( /* webpackChunkName: "move" */ '@/app/pages/Moves' );
            },
            meta: {
                type: 'squats'
            }
        },

        {
            path: '/win',
            name: 'Win',
            component: () =>
            {
                return import( /* webpackChunkName: "win" */ '@/app/pages/Win' );
            }
        },

        {
            path: '/naw',
            name: 'Naw',
            component: () =>
            {
                return import( /* webpackChunkName: "naw" */ '@/app/pages/Naw' );
            }
        },

        {
            path: '/bedankt',
            name: 'Bedankt',
            component: () =>
            {
                return import( /* webpackChunkName: "bedankt" */ '@/app/pages/Bedankt' );
            }
        }
    ]
});

appRouter.beforeEach( ( to, from, next ) =>
{
    if( typeof to.beforeEnter === 'function' ) // check if the route has a beforeEnter function
    {
        to.beforeEnter( to, from, next );
        return;
    }

    next();
});

export default appRouter;

<template>
    <div id="app">
        <svg-lib></svg-lib>

        <div class="langtoggle" @click="toggleLang">
            <img :src="'/static/img/' + lang + '.svg'" />
            <span>{{ lang === 'fr-be' ? 'FR' : 'NL' }}</span>
        </div>

        <ynk-layout>
            <transition mode="out-in"
                        @enter="getAnimateIn"
                        @leave="getAnimateOut"
                        :css="false"
                        appear>
                <router-view :key="$route.path" ref="currentView"></router-view>
            </transition>
        </ynk-layout>
    </div>
</template>

<style lang="scss">
    @import './static/scss/app.scss';
</style>

<script>
    import { gsap } from '@/vendor';
    import Main from '@/app/layouts/Main';
    import SVGLib from '@/app/components/SVGLib';

    export default {
        name: 'App',

        components: {
            'ynk-layout': Main,
            'svg-lib': SVGLib
        },

        computed: {
            lang()
            {
                let currentLang = this.$store.getters[ 'entry/all' ].locale;

                if( currentLang === 'fr-be' )
                {
                    return 'nl-be';
                }
                else
                {
                    return 'fr-be';
                }
            }
        },

        mounted()
        {
            window.addEventListener( 'resize', this.onResize );
            this.onResize();
        },

        methods: {
            toggleLang()
            {
                this.$i18n.locale = this.lang;
                this.$store.dispatch( 'setLang', this.lang );
            },

            onResize()
            {
                document.documentElement.style.setProperty( '--vh100', window.innerHeight + 'px' ); // fix for 100vh on iphones
            },

            getAnimateIn( el, done )
            {
                let tl = gsap.timeline({ onComplete: done });
                if( typeof this.$refs.currentView.getAnimateIn === 'function' )
                {
                    tl.add( this.$refs.currentView.getAnimateIn(), 0 );
                }
                else
                {
                    done();
                }
            },

            getAnimateOut( el, done )
            {
                let tl = gsap.timeline({ onComplete: done });
                if( typeof this.$refs.currentView.getAnimateOut === 'function' )
                {
                    tl.add( this.$refs.currentView.getAnimateOut(), 0 );
                }
                else
                {
                    done();
                }
            }
        }
    };
</script>

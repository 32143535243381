<template>
    <div :class="{ 'main-layout': true, [ 'landing-page' ]: routeName === 'Landing' }">
        <div class="flag" v-if="routeName !== 'Win'">
            <img src="/static/img/flag.svg" alt="" class="flag">
        </div>

        <div class="content">
            <slot></slot>
        </div>

        <div class="bol-wrapper" v-if="routeName === 'Landing' || routeName === 'Naw'">
            <img src="/static/img/rode-bol.svg" />
        </div>
    </div>
</template>

<style lang="scss" scoped>
</style>

<script>
    export default {
        computed: {
            routeName()
            {
                return this.$route.name;
            }
        }
    };
</script>

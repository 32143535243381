import Vue from 'vue';
import Vuex from 'vuex';

import entry from './modules/entry';

Vue.use( Vuex );
require( 'es6-promise' ).polyfill();

export default new Vuex.Store({
    strict: true,

    modules: {
        entry
    },

    state: {
    },

    getters: {
    },

    mutations: {
    },

    actions: {
        setLang: ( context, payload ) =>
        {
            context.dispatch( 'entry/merge', { locale: payload });
        }
    }
});

<template>
    <div class="landing-view">
        <img src="/static/img/tokyo.svg" alt="" class="tokyo-logo">
        <img src="/static/img/yakult.svg" alt="" class="yakult-logo">

        <h1>{{ $t("landing.header") }}</h1>
        <p>{{ $t("landing.copy") }}</p>

        <button @click="continueRoute">{{ $t("landing.button") }}</button>

        <img class="prijzen" src="/static/img/prijzen.png" />
        <p class="legal" v-html="$t('landing.legal')"></p>
    </div>
</template>

<style lang="scss">
</style>

<script>
    import { gsap } from '@/vendor';

    export default {
        name: 'Landing',

        mounted()
        {

        },

        methods: {
            isIos()
            {
                return [
                    'iPad Simulator',
                    'iPhone Simulator',
                    'iPod Simulator',
                    'iPad',
                    'iPhone',
                    'iPod'
                ].includes( navigator.platform ) ||
                    // iPad on iOS 13 detection
                    ( navigator.userAgent.includes( 'Mac' ) && 'ontouchend' in document );
            },

            continueRoute()
            {
                console.log( 'isIos', this.isIos() );

                if( this.isIos() )
                {
                    if( typeof DeviceMotionEvent === 'function' )
                    {
                        this.getPermission();
                        // iOS 13+
                        // window.DeviceMotionEvent.requestPermission().then( response =>
                        // {
                        //     console.log( response );
                        // });
                    }
                    else
                    {
                        // non iOS 13+
                        DeviceOrientationEvent.requestPermission().then( response =>
                        {
                            if( response === 'granted' )
                            {
                                // HET MAG!
                            }
                        });
                    }
                }

                if( screen.orientation )
                {
                    if( screen.orientation.lock )
                    {
                        screen.orientation.lock( 'portrait' );
                    }
                }

                this.$router.push( '/kies' );
            },

            getPermission()
            {
                if( typeof DeviceMotionEvent !== 'undefined' && typeof DeviceMotionEvent.requestPermission === 'function' )
                {
                    // (optional) Do something before API request prompt.
                    DeviceMotionEvent.requestPermission()
                        .then( response =>
                        {
                            // (optional) Do something after API prompt dismissed.
                            if( response === 'granted' )
                            {
                                window.addEventListener( 'devicemotion', e =>
                                {
                                    // do something for 'e' here.
                                });
                            }
                        })
                        .catch( console.error );
                }
                else
                {
                    // alert( 'DeviceMotionEvent is not defined' );
                }
            },

            getAnimateIn()
            {
                let tl = gsap.timeline({ delay: 1 });

                tl.from( '.tokyo-logo', { rotationY: 90, duration: 0.7 });
                tl.from( '.flag img', { autoAlpha: 0, top: '-=20', duration: 0.4 }, 0 );

                tl.from( '.yakult-logo', { y: -40, autoAlpha: 0, duration: 0.4 });

                tl.from( 'h1, p, button, .prijzen', { stagger: 0.1, opacity: 0 });

                return tl;
            },

            getAnimateOut()
            {
                let tl = gsap.timeline();

                tl.to( this.$el, { autoAlpha: 0 });

                return tl;
            }
        }
    };
</script>
